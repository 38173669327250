import { useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import imageCompression from "browser-image-compression";
import { showNotification } from "../redux/slices/notificationSlice";

interface ImageType {
  src?: string;
  fileId?: string;
  url: string;
  isLoading: boolean;
}

const ModerationErrorMapping = {
  "inappropriate or sensitive content": {
    title: "Content Violation Detected",
    message: "In accordance with our content moderation policies, we are unable to process images that contain prohibited or harmful material. Please ensure that your uploads adhere to our guidelines.",
  },
  "PII content": {
    title: "Personal Information Detected",
    message: "In compliance with our privacy policy, we are unable to process images containing personal information. Please refrain from uploading images that include any sensitive data.",
  },
};

const useUploadImages = () => {
  const [images, setImages] = useState<ImageType[]>([]);
  const dispatch = useDispatch();

  const isImageLoading = useMemo(() => images.some(image => image.isLoading), [images]);

  const addImage = (image: ImageType) => {
    setImages(prev => prev.concat(image));
  };

  const updateImageSrc = (fileId: string, src: string) => {
    setImages(prevImages => prevImages.map((image) => {
      return fileId === image.fileId ? { ...image, src, isLoading: false } : image;
    }));
  };

  const updateFileId = (url: string, fileId: string) => {
    setImages(prevImages => prevImages.map((image) => {
      return url === image.url && !image.fileId ? { ...image, fileId } : image;
    }));
  };

  const uploadImageFiles = (files: File[]) => {
    files.forEach(async (file) => {
      if (file.type.startsWith("image/")) {
        if (file.type !== "image/webp" && file.type !== "image/gif") {
          const compressedFile = await imageCompression(file, { maxWidthOrHeight: 1024 });
          uploadImage(compressedFile);
        }
        else {
          uploadImage(file);
        }
      }
    });
  };

  const uploadImage = async (file: File) => {
    let fileId;
    try {
      const url = URL.createObjectURL(file);
      addImage({
        isLoading: true,
        url,
      });
      const payload = {
        file_name: file.name,
      };
      const { data } = await axios.post(`${process.env.REACT_APP_SPL_URL}/v1/tradegpt/chats/files`, payload, { withCredentials: true });
      fileId = data.file_id;
      updateFileId(url, fileId);
      const response = await axios.put(data.upload_url, file, {
        headers: {
          "Content-Type": file.type,
        },
      });
      if (response.status === 200 || response.status === 204) {
        const { data: imageData } = await axios.put(`${process.env.REACT_APP_SPL_URL}/v1/tradegpt/chats/files/${data.file_id}/upload`, {
          status: "uploaded",
        }, { withCredentials: true });
        updateImageSrc(imageData.file_id, imageData.download_url);
      }
      else {
        removeImage(fileId);
        dispatch(showNotification({ title: "Something Unexpected Happened",
          message: "An error has occurred while processing your request. Try refreshing the page or attempt the action again. If the problem persists, please contact our support team.",
          severity: "error",
          horizontal: "center" }));
      }
    }
    catch (err) {
      let errorObj = { title: "Something Unexpected Happened", message: "An error has occurred while processing your request. Try refreshing the page or attempt the action again. If the problem persists, please contact our support team." };
      if (err.status === 422) {
        const errorMapping = ModerationErrorMapping[err.response?.data?.message];
        if (errorMapping) {
          errorObj = errorMapping;
        }
        removeImage(fileId);
      }
      else {
        deleteImage(fileId);
      }
      dispatch(showNotification({ ...errorObj, severity: "error", horizontal: "center" }));
    }
  };

  const removeImage = (fileId: string) => {
    setImages(images => images.filter(item => item.fileId !== fileId));
  };

  const deleteImage = (fileId: string) => {
    try {
      removeImage(fileId);
      axios.delete(`${process.env.REACT_APP_SPL_URL}/v1/tradegpt/chats/files/${fileId}`, { withCredentials: true });
    }
    catch {
      console.error("Delete image failed");
    }
  };

  const clearImages = () => {
    if (images.length) {
      images.forEach((image) => {
        URL.revokeObjectURL(image.url);
      });
    }
    setImages([]);
  };

  return {
    images,
    isImageLoading,
    deleteImage,
    clearImages,
    uploadImageFiles,
  };
};

export default useUploadImages;
