import { IconButton, Modal } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

interface ImageModalType {
  open: boolean;
  imageSrc: string;
  onClose: () => void;
}

const ImageModal: React.FC<ImageModalType> = ({
  open = false,
  imageSrc,
  onClose,
}) => {
  return (
    <Modal
      className="flex justify-center items-center bg-mui-black-60 [&>.MuiModal-backdrop]:bg-mui-black-60 "
      open={open}
      onClose={onClose}
    >
      <>
        <IconButton
          size="medium"
          onClick={onClose}
          className="!text-white/60"
          sx={{ position: "absolute", top: 16, right: 16 }}
        >
          <CloseRoundedIcon />
        </IconButton>
        <div className="shadow rounded-lg overflow-hidden">
          <img src={imageSrc} className="object-cover max-w-modal-mobile sm:max-w-modal-sm md:max-w-modal-md lg:max-w-modal-lg xl:max-w-modal-xl max-h-[756px]" alt="uploaded image" />
        </div>
      </>
    </Modal>
  );
};

export default ImageModal;
