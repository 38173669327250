import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { doSignOutClear } from "../../components/auth/helpers";
import { Ticker } from "../../assets/interfaces/interfaces";

type WatchlistProps = {
  tickers: Ticker[];
  expandedTicker: Ticker | null;
  searchResults: Ticker[];
  isLoading: boolean;
  removedIds: Array<string>;
  reorderIds: Array<string>;
  isMutating: boolean;
  isWatchlistDrawerOpen: boolean;
  isHighlightBadge: boolean;
  error: string | null;
  isAdding: boolean;
};

const initialState: WatchlistProps = {
  tickers: [],
  expandedTicker: null,
  searchResults: [],
  isLoading: false,
  removedIds: [],
  reorderIds: [],
  isMutating: false,
  isWatchlistDrawerOpen: false,
  isHighlightBadge: false,
  error: null,
  isAdding: false,
};

export const getTickersInWatchlist = createAsyncThunk(
  "watchlist/fetchTickers",
  async ({ userId }: { userId: string }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_SPL_URL}/v1/watchlist/${userId}`,
        { withCredentials: true },
      );
      return data.data;
    }
    catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response.status === 403) {
          doSignOutClear(dispatch);
          return;
        }
        if (error.response) {
          return rejectWithValue(error.response.data);
        }
      }
      return rejectWithValue("Unexpected error occurred");
    }
  },
);

export const searchTickerResults = createAsyncThunk(
  "watchlist/searchTickers",
  async (arg: string, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_SPL_URL}/search/${arg}`,
        { withCredentials: true },
      );
      return data;
    }
    catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response.status === 403) {
          doSignOutClear(dispatch);
          return;
        }
        if (error.response) {
          return rejectWithValue(error.response.data);
        }
      }
      return rejectWithValue("Unexpected error occurred");
    }
  },
);

export const deleteTickers = createAsyncThunk(
  "watchlist/deleteTickers",
  async (
    { ids, userId }: { ids: string[]; userId: string },
    { rejectWithValue, dispatch },
  ) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_SPL_URL}/v1/watchlist/${userId}/tickers`,
        {
          data: { tickers: ids },
          withCredentials: true,
        },
      );
    }
    catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response.status === 403) {
          doSignOutClear(dispatch);
          return;
        }
        if (error.response) {
          return rejectWithValue(error.response.data);
        }
      }
      return rejectWithValue("Unexpected error occurred");
    }
  },
);

export const addTicker = createAsyncThunk(
  "watchlist/addTicker",
  async (
    { userId, ticker }: { userId: string; ticker: string },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const query = {
        ticker,
      };
      const { data } = await axios.post(
        `${process.env.REACT_APP_SPL_URL}/v1/watchlist/${userId}/tickers`,
        query,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        },
      );
      return data.data[0];
    }
    catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response.status === 403) {
          doSignOutClear(dispatch);
          return;
        }
        if (error.response) {
          return rejectWithValue(error.response.data.error || "Fail to add ticker");
        }
      }
      return rejectWithValue("Unexpected error occurred");
    }
  },
);

export const updateTickerSequence = createAsyncThunk(
  "watchlist/updateTickerSequence",
  async (
    {
      payload,
      userId,
    }: { payload: { ticker: string; seq: number }[]; userId: string },
    { rejectWithValue, dispatch },
  ) => {
    try {
      return await axios.put(
        `${process.env.REACT_APP_SPL_URL}/v1/watchlist/${userId}/tickers`,
        {
          sequence: payload,
        },
        { withCredentials: true },
      );
    }
    catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response.status === 403) {
          doSignOutClear(dispatch);
          return;
        }
        if (error.response) {
          return rejectWithValue(error.response.data);
        }
      }
      return rejectWithValue("Unexpected error occurred");
    }
  },
);

export const watchlistSlice = createSlice({
  name: "watchlist",
  initialState,
  reducers: {
    setExpandedTicker: (state, action) => {
      if (state.expandedTicker === action.payload) {
        state.expandedTicker = null;
      }
      else {
        state.expandedTicker = action.payload;
      }
    },
    resetSearchResults: (state) => {
      state.searchResults = [];
    },
    removeTicker: (state, action) => {
      state.removedIds = [...state.removedIds, action.payload];
    },
    updateTickerOrder: (state, action: { type: string; payload: string[] }) => {
      state.reorderIds = action.payload;
    },
    resetRemoveList: (state) => {
      state.removedIds = [];
    },
    resetReorderList: (state) => {
      state.reorderIds = [];
    },
    setWatchlistDrawerState: (state, action) => {
      state.isWatchlistDrawerOpen = action.payload;
    },
    setIsHighlightBadge: (state, action) => {
      state.isHighlightBadge = action.payload;
    },
    resetError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTickersInWatchlist.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTickersInWatchlist.fulfilled, (state, action) => {
        state.isLoading = false;
        state.tickers = action.payload;
        state.expandedTicker = action.payload[0]?.ticker;
      })
      .addCase(searchTickerResults.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(searchTickerResults.fulfilled, (state, action) => {
        state.isLoading = false;
        state.searchResults = action.payload;
        state.expandedTicker = action.payload[0]?.ticker;
      })
      .addCase(addTicker.pending, (state) => {
        state.isAdding = true;
      })
      .addCase(addTicker.fulfilled, (state, action) => {
        state.isAdding = false;
        state.tickers = [action.payload, ...state.tickers]; // add new ticker to the top of the tickers list
      })
      .addCase(addTicker.rejected, (state) => {
        state.isAdding = false;
        state.error = "watchlist has reached maximum size";
      })
      .addCase(deleteTickers.pending, (state) => {
        state.isMutating = true;
      })
      .addCase(updateTickerSequence.pending, (state) => {
        state.isMutating = true;
      })
      .addCase(deleteTickers.fulfilled, (state) => {
        state.isMutating = false;
      })
      .addCase(deleteTickers.rejected, (state) => {
        state.isMutating = false;
      })
      .addCase(updateTickerSequence.fulfilled, (state) => {
        state.isMutating = false;
      })
      .addCase(updateTickerSequence.rejected, (state) => {
        state.isMutating = false;
      });
  },
});
export const {
  setExpandedTicker,
  resetSearchResults,
  removeTicker,
  updateTickerOrder,
  resetRemoveList,
  resetReorderList,
  setWatchlistDrawerState,
  setIsHighlightBadge,
  resetError,
} = watchlistSlice.actions;

export default watchlistSlice.reducer;
