import React from "react";
import { numberFormatter } from "../../helpers";
import { usePrice } from "./hooks";
import { Skeleton } from "@mui/material";

interface PriceProps {
  ticker: string;
  prevClose: number | null;
  lastPrice: number | null;
}

const Price: React.FC<PriceProps> = ({ ticker, prevClose, lastPrice }) => {
  const { currentPrice, priceChangePercentage, priceChangeClass, isFetchPrevCloseLoading } = usePrice(ticker, prevClose, lastPrice);

  let priceToDisplay = "N/A";

  if (currentPrice > 0) {
    priceToDisplay = numberFormatter(currentPrice);
  }

  return (
    <div className="text-right tabular-nums flex flex-col items-center justify-end space-x-2">
      {currentPrice === undefined
        ? (
            <Skeleton
              variant="text"
              animation="wave"
              width={80}
              height={30}
              className="self-end dark:bg-white dark:opacity-12"
            />
          )
        : <span>{priceToDisplay}</span>}
      <p className={`body2 text-right tabular-nums ${priceChangeClass} self-end`}>
        {isFetchPrevCloseLoading
          ? (
              <Skeleton variant="text" animation="wave" width={30} height={20} className="self-end dark:bg-white dark:opacity-12" />
            )
          : (
              priceChangePercentage !== null
                ? `${priceChangePercentage > 0 ? "+" : ""}${priceChangePercentage.toFixed(2)}%`
                : "—"
            )}
      </p>
    </div>
  );
};

export default Price;
