import { BaseTextFieldProps } from "@mui/material";
import { AlertColor } from "@mui/material/Alert";

// TypeScript type for the function parameters
type InputType = "recommend" | "user_type";
type statusType = "idle" | "loading" | "succeeded" | "failed";
type CloseModalFunction = () => void;
type DispatchFunction = (action: { type: string; payload? }) => void;
type TextFieldOnChangeHandler = (
  event: React.ChangeEvent<HTMLInputElement>
) => void;

export interface RenderPhoneFieldFunc {
  label: string;
  error: string;
  value: string;
  onChange: (value: string) => void;
}

export interface RenderTextFieldFunc {
  label: string;
  value: string;
  onChange: TextFieldOnChangeHandler;
  error: string;
  type?: string;
}

export interface SignInModalProps {
  setFlow: (flow: number) => void;
  setOpen: (open: boolean) => void;
  flow: number;
  open: boolean;
}

export type ValidationFormFunc = (
  email: string,
  password: string
) => ValidationResult;

export type PostMessagesFunction = (
  text: string,
  input_type: InputType,
  token?: string,
  attachments?: MessageAttachments
) => void;

export type OauthSignInFucntion = (
  closeModal: CloseModalFunction,
  dispatch: DispatchFunction,
  navigate?: (path: string) => void
) => void;

export type sendMessageFunction = (text: string, input_type: InputType, attachments?: MessageAttachments) => void;
export interface DefaultSignInProps {
  emailValue: string;
  setFlow: (flow: number) => void;
  setOpen?: (open: boolean) => void;
}
// Define a type for individual chat message
export interface SubmenuItemProps {
  item: {
    sessionId: string;
    title?: string; // Title of the sub menu item.
  };
  clearAllChatData: () => void;
}
export interface ChatMessage {
  date: string;
  title: string;
  sessionId: string;
}
export interface SettingsModalInterface {
  open: boolean;
  setOpen: (isSettingsModalOpen: boolean) => void;
  generalActive: boolean;
  setGeneralActive: (general: boolean) => void;
  handleProfileMenuClose: () => void;
  clearAllChatData: () => void;
  handleSignOut: () => void;
}
export interface PreferencesModalInterface {
  open: boolean;
  setOpen: (isSettingsModalOpen: boolean) => void;
}
export interface ValidateUserState {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
  isValid: boolean;
}

export interface DeleteChatArg {
  session_id: string | null | undefined;
  user_id: string | null | undefined;
}

export interface DeleteUserArg {
  user_id: string | null | undefined;
}
export interface SamplePrompt {
  description: string;
  title: string;
  type: string;
}

type RecommendedStocksTypes = "quick_trades" | "top_picks";
type RecommendedOptionsTypes = "hot_trades" | "market_movers";

export interface RecommendedPrompts {
  stocks: {
    [key in RecommendedStocksTypes]: {
      query: string;
    }[]
  };
  options: {
    [key in RecommendedOptionsTypes]: {
      query: string;
    }[]
  };
}
export interface AccordianInterface {
  title: React.ReactNode;
  description: React.ReactNode;
}
// Define a type for the slice state

export interface PromptsState {
  prompts: SamplePrompt[];
  status: statusType;
  error: string | null;
}
export interface RecommendedPromptsState {
  prompts: RecommendedPrompts;
  promptSelected: string | null;
  status: statusType;
  error: string | null;
}

export interface ChatState {
  messages: ChatMessage[];
  tempMessages: ChatMessage[];
  status: statusType;
  error: string | null;
}

export interface DeleteChatState {
  status: statusType;
  error: string | null;
}

export interface DeleteChatArg {
  session_id: string | null | undefined;
  user_id: string | null | undefined;
}

export interface DeleteAllChatSessionsArg {
  userId: string | null | undefined;
}

export interface changeSubArg {
  price_id?:
    | string
    | null
    | undefined
    | "TradeGpt-Premium-USD-Monthly"
    | "TradeGpt-Pro-USD-Monthly";
  cancel: boolean;
}

export interface fetchUserArg {
  source?: string | null | undefined;
  cje?: string | null | undefined;
}

export interface fetchUserPrefArg {
  userId: string | null | undefined;
  preferences?: Preference;
}

export interface DeleteSessionsState {
  status: statusType;
  error: string | null;
}

export interface changeSubState {
  checkoutObject;
  status: statusType;
  error: string | null;
}

export interface NewTitleState {
  title: string;
  status: statusType;
  error: string | null;
}
export interface PastChatState {
  pastMessages: ChatMessage[];
  status: statusType;
  error: string | null;
}
export interface ActivePlanState {
  plan: {
    promptsLeft: number;
    subscription_type: "Free" | "Pro" | "Premium";
    subscription;
  };
  status: statusType;
  error: string | null;
}
export interface AllPlanState {
  plans: unknown[];
  status: statusType;
  error: string | null;
}
export interface UserState {
  user: User;
  status: statusType;
  error: string | null;
}
export interface UserPreferenceState {
  userId?: string;
  preferences: Preference | null;
  status: string;
  error: string | null;
  hasOpenedModal: boolean | null;
}
export interface TokenState {
  token: string;
  status: statusType;
  error: string | null;
}

export interface updateFirestoreState {
  firestoreFields;
  status: statusType;
  error: string | null;
}
// Define a type for the thunk argument
export interface FetchChatsArg {
  userId: string | undefined | null;
}
export interface FetchSessionArg {
  sessionId: string | undefined | null;
}
export interface FetchPlanArg {
  userId: string | undefined | null;
}

export interface ChangeTitleArg {
  title: string | undefined;
  session_id: string | undefined;
  userId: string;
  fetchSession?: boolean;
}

export interface SendFeedbackArg {
  message_id: string;
  is_thumbs_up: boolean;
  feedback_text?: string;
  reason_cat?: string[];
}

export interface RootState {
  finchat;
  FinchatGPT: {
    finchatMessage; // Replace 'any' with the actual type
  };
  auth: {
    loading: boolean;
    currentUser;
  };
  chat: ChatState;
  session;
  prompts: PromptsState;
  sharedSession;
}
export interface MessageInputProps {
  sessionId: string;
  handleSendMessage: sendMessageFunction;
  setOpen: (open: boolean) => void;
  setMessages;
  setFlow: (flow: number) => void;
  setSignInModalOpen: (shouldOpen: boolean) => void;
  handleCancel: () => void;
  showPrompts: boolean;
  handlePromptSelect: (prompt: string) => void;
}
export interface UserAuthData {
  email: string;
  password: string;
  displayName: string;
  phoneNumber: string;
  landing?: boolean;
}
export interface FormData {
  email: string;
  name: string;
  phoneNumber: string;
  password: string;
}

export interface ValidationResult {
  valid: boolean;
  errors;
}
export interface TypingAnimationProps {
  text: string;
  speed?: number;
  onComplete?: () => void;
  components?: {
    [key: string]: React.ComponentType<React.AnchorHTMLAttributes<HTMLAnchorElement>> | ((props: React.AnchorHTMLAttributes<HTMLAnchorElement>) => JSX.Element);
  };
}
export interface UserArg {
  token: string;
  isMobileApp?: boolean;
}

export interface FirestoreArg {
  firestoreFields;
  source?: string;
}
export interface User {
  accessToken: string;
  userId: string | null;
  phoneNumber: string;
  feature_flags: Record<string, unknown> | string;
  country: string;
  state: string;
  email: string;
  display_name?: string;
  displayName?: string;
  name: string;
  ip: string;
  sign_in_attempts: number | string;
  photoURL: string | null;
}
export interface Message {
  message_id?: string;
  chart_data: unknown[]; // Replace 'any' with a more specific type if possible
  chart_flag: boolean;
  chat_id: string;
  input: string;
  output: string;
  isBot: boolean;
  outputLoading: boolean;
  list_of_tickers: string[];
  list_of_recommendations: Recommendation[];
  attachments?: MessageAttachments;
}
export interface Preference {
  tradingFocus: "equity" | "options" | "any";
  marketCap: "large" | "medium" | "small" | "any";
  riskAppetite: "high" | "medium" | "small" | "any";
}
export interface MUIPhoneProps extends BaseTextFieldProps {
  value: string;
  onChange: (phone: string) => void;
}

export interface ChatWindowProps {
  sessionId: string;
  messages: Partial<Message>[];
  isMobile: boolean;
  isTablet: boolean;
  setOpen: (open: boolean) => void;
  isUserSignedIn: boolean;
  shareModal: boolean;
  setShareModal: (boolean) => void;
  handleSendMessage?: (text: string, input_type: string) => void;
}

export interface PlanCardProps {
  planName: string;
  price: string;
  billingCycle: string;
  prompts: number | string;
  buttonText: string;
  buttonType: "primary" | "default" | "disabled";
  mostPopular?: boolean;
  id?: string;
}

export interface SidebarProps {
  isMobile: boolean;
  isDarkMode: boolean;
  handleNewChat: () => void;
  isTablet: boolean;
  drawerOpen: boolean;
  setDrawerOpen: (bool: boolean | ((bool: boolean) => boolean)) => void;
  toggleDrawer: () => void;
  signInModalOpen: boolean;
  setSignInModalOpen: (shouldOpen: boolean) => void;
  flow: number;
  setFlow: (num: number) => void;
  messages: unknown[];
  setMessages;
}

export interface SeriesItem {
  type: string;
  name: string;
  data: Array<unknown>;
  tooltip?;
}

export interface MessageAttachments {
  images?: {
    image_url: string;
    file_id: string;
  }[];
}
export interface FinchatMessagePayload {
  input: string;
  chat_id: string;
  session_id: string;
  user_id: string;
  accessToken: string;
  input_type: InputType;
  attachments?: MessageAttachments;
}

export interface FinchatResponsePayload {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  chart_data: any[];
  chat_id: string;
  chart_flag: boolean;
  session_id: string;
  list_of_tickers: string[];
  list_of_recommendations: Recommendation[];
}

export interface UserPreferencePayload {
  preferences: Preference;
}

export interface NotificationSnackbarProps {
  open: boolean;
  onClose: () => void;
  message: string;
  title?: string;
  severity: AlertColor; // Use AlertColor type from Material-UI
  horizontal: "right" | "center";
}

export interface NotificationState {
  open: boolean;
  message: string;
  title?: string;
  severity: "success" | "error" | "info" | "warning";
  horizontal: "right" | "center";
}

export interface IntroPromoState {
  show: boolean;
}

export interface GeneralModalProps {
  open: boolean;
  handleClose: () => void;
  session_id?: string;
}

export interface Point {
  id: number;
  text: string;
}

export interface ChangeLog {
  id: string;
  title: string;
  date: string;
  description: string;
  version: string;
  version_type: string;
  categories: string[];
  status: string;
  is_public_visible: boolean;
}

export interface ChangeLogsState {
  lastViewed?: string;
  logs: ChangeLog[];
  isLoading: boolean;
}

export type Ticker = {
  ticker: string;
  seq?: string;
  name?: string;
};

export type TickerInfo = {
  name: string;
  prev_close_price: number | null;
  last_price: number | null;
};

export interface TradierUserPayload {
  user: TradierUser;
}

export interface TradeIdeaWrapper {
  user_id: string;
  trade_idea: TradeIdea;
}

export interface TradeIdeaOption {
  id: number;
  user_id: string;
  trade_idea: TradeIdeaWrapper;
  combination_id: string | null;
  status: string;
  message_id: string | null;
  created_at: string;
}

export interface TradierVirtualAccountPayload {
  account: TradierVirtualAccount | null;
}

export interface TradierUser {
  id: number;
  name: string;
  email: string;
  api_key: string;
}

export interface TradierVirtualAccount {
  id: number;
  external_id: string;
  balance_cash: number;
}

export interface Order {
  account: string;
  avg_fill_price: string;
  class: string;
  company: string;
  create_date: string;
  event: string;
  exec_quantity: number;
  external_order_id: string;
  id: string;
  last_fill_price: string;
  last_fill_quantity: number;
  option_symbol: string;
  parent_id: string | null;
  price: string;
  raw_order?: {
    class: string;
    duration: string;
    option_symbol: string;
    price: string;
    quantity: string;
    side: string;
    type: string;
  };
  legs?: Order[];
  remaining_quantity: number;
  side: string;
  status: string;
  stop_price: string;
  symbol: string;
  total_quantity: string;
  transaction_date: string;
  transaction_value: string;
  type: string;
}

type TradeIdeaLeg = {
  buy_sell: string;
  duration: string;
  expiry_date: string;
  premium: number;
  put_call: string;
  quantity: number;
  strike_price: number;
  type: string;
};

export interface Recommendation {
  break_even_price: number[];
  combination_id: number;
  current_price: number;
  duration: string;
  legs: TradeIdeaLeg[];
  max_loss: number | null;
  max_profit: number | null;
  option_strategy: string;
  position: string;
  prob_of_profit: number;
  profit: number | null;
  stop: number | null;
  symbol: string;
  type: string;
}

export interface PreviewResultOrder {
  class: string;
  commission: number;
  cost: number;
  day_trades: number;
  duration: string;
  extended_hours: boolean;
  fees: number;
  margin_change: number;
  option_requirement: number;
  order_cost: number;
  request_date: string;
  result: boolean;
  status: string;
  strategy: string;
  symbol: string;
  type: string;
}
export interface PreviewResult {
  balance: number;
  cost: number;
  orders: PreviewResultOrder[];
}
export interface TradeIdea {
  combination_id: number;
  created_at: string;
  id: number;
  status: string;
  user_id: string;
  preview_result?: PreviewResult;
  trade_idea?: Recommendation;
}

export interface TradeIdeasLoad {
  trade_ideas: TradeIdea[];
}

export enum TradeStrategies {
  Buy = "buy",
  Sell = "sell",
  sell_to_open = "sell_to_open",
  Long_Call = "Long Call",
  Long_Put = "Long Put",
  Short_Call = "Short Call",
  Short_Put = "Short Put",
  Put = "put",
  Call = "call",
  Straddle = "Straddle",
}

export enum Sides {
  sell_to_close = "Sell to Close",
  sell_to_open = "Sell to Open",
  buy_to_open = "Buy to Open",
  buy_to_close = "Buy to Close",
}

export enum StopLossTakeProfit {
  stop = "Stop Loss",
  limit = "Take Profit",
}

export enum ErrorType {
  Mapping = "mapping",
  Preview = "preview",
  Execute = "execute",
  AfterHours = "afterhours",
}
