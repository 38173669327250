import { useEffect, useState, useCallback, useRef } from "react";
import { Ticker, TickerInfo } from "../../assets/interfaces/interfaces";
import { Button, CircularProgress, IconButton, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { MODE } from "./constants";
import CrossSVG from "../Svgs/cross";
import SparklineChart from "../Shared/Sparkline";
import moment from "moment";
import { fetchSparkline } from "../api/fetchSparkline";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { removeTicker, setWatchlistDrawerState } from "../../redux/slices/watchlistSlice";
import { ReactComponent as DragHandle } from "../../assets/icons/DragHandle.svg";
import { PromptList, getPrompt } from "./constants";
import "./index.scss";
import { ReactComponent as PlusFilledCircle } from "../../assets/icons/PlusFilledCircle.svg";
import { ReactComponent as CheckFilledCircle } from "../../assets/icons/CheckFilledCircle.svg";
import Price from "./Price";
import { RootState } from "../../assets/interfaces/interfaces";
import { usePrice } from "./hooks";

type TickerProps = {
  ticker: Ticker;
  isSelected: boolean;
  mode: `${MODE}`;
  tickerData: TickerInfo | null;
  className?: string;
  onDragOver: (e, id: string) => void;
  onDrop: (e, id: string) => void;
  onDragStart: (e, id: string) => void;
  onDragLeave: () => void;
  handlePrompt: (msg: string) => void;
  onAddTicker: (string) => void;
};

export default function TickerCard({
  ticker,
  isSelected,
  mode,
  tickerData,
  className,
  onDragStart,
  onDrop,
  onDragOver,
  handlePrompt,
  onDragLeave,
  onAddTicker,
}: TickerProps) {
  const [series, setSeries] = useState(null);
  const dispatch = useDispatch<AppDispatch>();
  const { isMutating, tickers } = useSelector(
    (state: { watchlist }) => state.watchlist,
  );
  const isMessageStreaming = useSelector((state: RootState) => state?.finchat?.loading);
  const [isTickerInWatchlist, setIsTickerInWatchlist] = useState(
    tickers.some(existingTicker => existingTicker.ticker === ticker?.ticker) || false,
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { priceChange, isFetchPrevCloseLoading } = usePrice(ticker.ticker, tickerData?.prev_close_price, tickerData?.last_price);

  const handleButtonClick = useCallback(
    (event, promptType, tickerName) => {
      event.stopPropagation();
      const prompt = getPrompt(promptType, tickerName);
      handlePrompt(prompt);
      if (isMobile) {
        dispatch(setWatchlistDrawerState(false));
      }
    },
    [handlePrompt],
  );

  const fetchSparklineData = async (signal) => {
    const toTime = moment().format("YYYY-MM-DD");
    const fromTime = moment().subtract(7, "days").format("YYYY-MM-DD");

    try {
      const data = await fetchSparkline(ticker?.ticker, fromTime, toTime, signal);
      if (data && data.results) {
        const seriesForSparkline = data.results
          .slice(0, 16)
          .map(result => result.c)
          .reverse();
        if (Array.isArray(seriesForSparkline) && seriesForSparkline.length >= 16) {
          setSeries(seriesForSparkline);
        }
        else {
          setSeries("Data unavailable");
        }
      }
      else {
        setSeries("Data unavailable");
      }
    }
    catch (error) {
      if (error.name !== "CanceledError") {
        console.error("Error fetching sparkline data:", error);
        setSeries("Data unavailable");
      }
    }
  };
  const onClickDelete = useCallback((e, id: string) => {
    e.preventDefault();
    dispatch(removeTicker(id));
  }, []);

  const onClickAddTicker = useCallback(async () => {
    if (isTickerInWatchlist) {
      return;
    }
    setIsTickerInWatchlist(true); // Optimistic UI update
    try {
      await onAddTicker(ticker?.ticker);
    }
    catch (e) {
      console.error("Error adding ticker to watchlist:", e);
      setIsTickerInWatchlist(false);
    }
  }, [onAddTicker, ticker]);

  const tickerRef = useRef(null);

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;

    const fetchDataIfVisible = async ([entry]) => {
      if (entry.isIntersecting) {
        if (tickerRef.current) {
          observer.unobserve(tickerRef.current); // can stop observing the ref once fetching starts
        }
        await fetchSparklineData(signal);
      }
    };

    const observer = new IntersectionObserver(fetchDataIfVisible, {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    });

    if (tickerRef.current) {
      observer.observe(tickerRef.current);
    }

    return () => {
      observer.disconnect();
      abortController.abort();
    };
  }, [ticker]);
  const tickerName = mode === MODE.SEARCH ? ticker.name : tickerData?.name;

  return (
    <div
      ref={tickerRef}
      className={`flex flex-col w-full draggable-item cursor-pointer ${className}`}
      draggable={!isMutating && mode === MODE.LIST_MANAGEMENT}
      onDragOver={e => onDragOver(e, ticker.ticker)}
      onDrop={e => onDrop(e, ticker.ticker)}
      onDragStart={(e) => {
        onDragStart(e, ticker.ticker);
      }}
      onDragLeave={onDragLeave}
    >
      <div className="flex w-full items-center">
        {mode === MODE.LIST_MANAGEMENT && (
          <IconButton
            size="small"
            onClick={e => onClickDelete(e, ticker.ticker)}
            className="cross-icon"
            disableRipple={true}
            sx={{ padding: "0px" }}
            disabled={isMutating}
          >
            <CrossSVG color="#D32F2F" />
          </IconButton>
        )}
        {mode === MODE.SEARCH && (
          <IconButton
            size="small"
            onClick={() => onClickAddTicker()}
            disabled={isTickerInWatchlist}
          >
            {isTickerInWatchlist
              ? (
                  <CheckFilledCircle className="m-1" />
                )
              : (
                  <PlusFilledCircle className="h-5 w-5 fill-current m-1" />
                )}
          </IconButton>
        )}
        <div className="flex justify-between p-1 items-center body1 h-[76px] w-full sm:w-[328px]">
          <div className="flex flex-col w-[90px]">
            <p className="body1 font-semibold">{ticker.ticker}</p>
            <Tooltip
              title={tickerName}
              placement="left"
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [0, -8],
                      },
                    },
                  ],
                },
              }}
            >
              <p className="body2 text-mui-black-60 dark:text-mui-white-70 whitespace-nowrap overflow-hidden text-ellipsis">
                {tickerName}
              </p>

            </Tooltip>
          </div>
          <div className={mode === MODE.VIEW ? "ps-2" : "w-[90px]"}>
            {series === null || isFetchPrevCloseLoading
              ? (
                  <div className="flex justify-center items-center">
                    <CircularProgress disableShrink color="inherit" className="text-tgpt-secondary-main" size={16} />
                  </div>
                )
              : Array.isArray(series) && priceChange !== null
                ? (
                    <div className="transition-all duration-500">
                      <SparklineChart series={series} priceChange={priceChange} />
                    </div>
                  )
                : (
                    <p className="text-mui-black-60 dark:text-mui-white-70 text-xs text-center">
                      Data
                      <br />
                      Unavailable
                    </p>
                  )}
          </div>
          <div className={`flex flex-col items-end ${mode !== MODE.SEARCH ? "w-[90px]" : "w-[70px]"}`}>
            <Price ticker={ticker.ticker} prevClose={tickerData?.prev_close_price} lastPrice={tickerData?.last_price} />
          </div>
        </div>
        {mode === MODE.LIST_MANAGEMENT && (
          <div className="h-5 cursor-grab drag-handle">
            <DragHandle className="fill-current" />
          </div>
        )}
      </div>

      {isSelected && (mode !== MODE.LIST_MANAGEMENT) && (
        <div className="pb-2 flex flex-wrap gap-1">
          {PromptList.map((type, index) => (
            <Button
              key={index}
              className="button-outlined"
              variant="outlined"
              color="inherit"
              size="small"
              // Disable prompt button when name is not loaded or a prompt query is goingon
              disabled={!ticker.ticker || isMessageStreaming}
              onClick={e => handleButtonClick(e, type, ticker.ticker)}
            >
              <p className="tracking-[0.46px]">{type}</p>
            </Button>
          ))}
        </div>
      )}
    </div>
  );
}
