import { useDispatch, useSelector } from "react-redux";
import { setIsHighlightBadge } from "../../redux/slices/watchlistSlice";
import { useRef, useMemo, useEffect } from "react";

export const useIncrementTrigger = () => {
  const dispatch = useDispatch();
  const timerRef = useRef(null);

  const updateBadgeCount = () => {
    dispatch(setIsHighlightBadge(true));
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      dispatch(setIsHighlightBadge(false));
      timerRef.current = null;
    }, 3000);
  };

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  return { updateBadgeCount };
};

export const usePrice = (ticker: string, prevClose: number | null, lastPrice: number | null) => {
  const { marketData } = useSelector((state: { dxFeed }) => state.dxFeed);
  const currentPrice = marketData[ticker] ?? lastPrice;
  const isFetchPrevCloseLoading = prevClose === undefined;

  const { priceChange, priceChangePercentage } = useMemo(() => {
    if (currentPrice == null) {
      return { priceChange: null, priceChangePercentage: null };
    }

    if (prevClose == null) {
      return { priceChange: currentPrice, priceChangePercentage: null };
    }

    const priceChange = currentPrice - prevClose;
    const priceChangePercentage = prevClose > 0 ? (priceChange / prevClose) * 100 : 0;

    return { priceChange, priceChangePercentage };
  }, [currentPrice, prevClose]);

  const priceChangeClass = useMemo(() => {
    if (priceChange === null || priceChangePercentage === null) return "";
    return priceChange < 0 ? "text-text-error-main" : "text-text-success-main";
  }, [priceChange, priceChangePercentage]);

  return { currentPrice, priceChange, priceChangePercentage, priceChangeClass, isFetchPrevCloseLoading };
};
